import { Platform } from "react-native";

export default {
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    freeContent: [
        {
            id: 1,
            title: 'ORIGINS',
            uri: 'https://media.istockphoto.com/id/481229372/photo/spiral-galaxy-illustration-of-milky-way.webp?s=612x612&w=is&k=20&c=SnHIYlKXaX3Bwd_W6kIWFdTmeh49Z1I3ZcpeIu6Sei4='
        },
        {
            id: 2,
            title: 'FOUNDATIONS',
            uri: 'https://media.istockphoto.com/id/453410847/photo/spiral-galaxy-milky-way.webp?s=612x612&w=is&k=20&c=4_yg7_gke--lvoPLk_DBf80kLxVl9ZOuJpvjJfUWoTQ='
        },
        {
            id: 3,
            title: 'LONGEVITY',
            uri: 'https://media.istockphoto.com/id/1169827279/photo/planets-and-galaxy-cosmos-physical-cosmology-science-fiction-wallpaper.webp?s=612x612&w=is&k=20&c=dGzxPdu2h3xBZlQza1Iy0N9R5TwBma3WF8JPO2rQ1ic='
        },
        {
            id: 4,
            title: 'SUPPLE',
            uri: 'https://media.istockphoto.com/photos/clean-energy-wind-power-stations-picture-id1413152090'
        }
    ],
    yourJourney: [
        {
            id: 1,
            title: 'Universe Attunement',
            price: '100$',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
            thumbImageURL: 'https://media.istockphoto.com/id/1254393889/photo/black-hole-over-star-field-in-outer-space-abstract-space-wallpaper-with-copy-space-elements.webp?s=612x612&w=is&k=20&c=b1WunbAXnIPVVCkTM2b6m4oFGzcCQ71_YMVmieDtqkU='
        },
        {
            id: 2,
            title: 'Morning and evening Activation',
            price: '100$',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
            thumbImageURL: 'https://images.unsplash.com/photo-1462332420958-a05d1e002413?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1507&q=80'
        },
        {
            id: 3,
            title: '81 Verses',
            price: '100$',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
            thumbImageURL: 'https://images.unsplash.com/photo-1540458638618-893e9fb35c07?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=327&q=80'
        },
        {
            id: 4,
            title: 'Sacred Brain Breath',
            price: '100$',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
            thumbImageURL: 'https://media.istockphoto.com/id/182792016/photo/solar-system.webp?s=612x612&w=is&k=20&c=ZMtqgBg4ua_ZB3blT357A-sVZEkgyn4uvGHneFAh430='
        },
        {
            id: 5,
            title: 'Sacred Heart Breath',
            price: '100$',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
            thumbImageURL: 'https://images.unsplash.com/photo-1447433693259-c8549e937d62?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=553&q=80'
        },
        {
            id: 6,
            title: 'Sacred Gut Breath',
            price: '100$',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
            thumbImageURL: 'https://images.unsplash.com/photo-1484589065579-248aad0d8b13?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=459&q=80'
        },
        {
            id: 7,
            title: 'Sacred Infinite Flowers',
            price: '100$',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
            thumbImageURL: 'https://images.unsplash.com/photo-1529788295308-1eace6f67388?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80'
        },
        {
            id: 8,
            title: 'Sacred Numbers',
            price: '100$',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
            thumbImageURL: 'https://images.unsplash.com/photo-1610209455607-89e8b3e0e393?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'
        },
        {
            id: 9,
            title: 'Sacred Word',
            price: '100$',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
            thumbImageURL: 'https://images.unsplash.com/photo-1446941611757-91d2c3bd3d45?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=502&q=80'
        },
        {
            id: 10,
            title: 'Sacred Geometry',
            price: '100$',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s',
            thumbImageURL: 'https://images.unsplash.com/photo-1609004871252-813bba39eb72?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80'
        }
    ],
    sourceData: [
        {
            id: 1,
            title: 'FAQ'
        },
        {
            id: 2,
            title: 'About Us'
        },
        {
            id: 3,
            title: 'Testimonials'
        },
        {
            id: 4,
            title: 'Contact Us'
        },
        {
            id: 5,
            title: 'EULA'
        },
        {
            id: 6,
            title: 'Terms of Service'
        },
        {
            id: 7,
            title: 'Privacy Policy'
        },
        {
            id: 8,
            title: 'Delete Account'
        }

    ],
    apiEndPoints: {
        login: 'auth/login',
        changePassword: 'updatePassword',
        signUp: 'auth/register',
        forgotPassword: 'auth/forget-password',
        resetPassword: 'auth/reset-password',
        logout: 'logout',
        getTiers: 'getTiers',
        getLevel: 'getLevelByTierId/',
        getExpansion: 'expansion-videos',
        updateUserProgress: 'update-user-progress',
        contactUs: 'contact-us',
        paymentUrl: 'getPaymentUrl',
        getProfile: 'getUserProfile',
        updateProfile: 'updateUserProfile',
        cancelSub: 'cancel-subscription',
        verifyPayment: 'verifyPayment',
        saveVideoPayment: 'saveVideoPayment',
        saveVideoPaymentForAndroid: 'saveVideoPaymentForAndroid',
        verifyAndroidPayment: 'verifyAndroidPayment',
        deleteAccount: 'deleteUser'

    },
    appKey: {
        kAuthToken: 'kAuthToken',
        kDeviceToken: 'kDeviceToken',
        kUserEmail: 'kUserEmail',
        kUserPassword: 'kUserPassword',
        kUserId: 'kUserId',
        kUserName: 'kUserName',
        kUserEmail: 'kUserEmail',
        isVerifedUser: 'isVerifedUser',
        loginData: 'loginData',
        isRememberMe: 'isRememberMe',
        kSubscription: 'subscription',
        kDecodeKey: 'kDecodeKey',
        isComeFromLogin: 'isComeFromLogin',
        paramKey: 'theWayAppSafeParam',
        shouldPopToRoot: 'shouldPopToRoot'



    },
    URL: {
        // BASE_URL: 'https://projects8.sofmen.com/thewayapp/public/api/', // staging
        BASE_URL: 'https://admin.theway126.com/api/', // Production

        //  
        IMAGE_URL: 'https://projects8.sofmen.com/thewayapp/public/',

        ABOUT_US_URL: Platform.OS == 'web' ? "https://theway126.com/about-us.html" : 'https://theway126.com/about.html',
        TESTIMONIAL: Platform.OS == 'web' ? 'https://theway126.com/testimonials.html' : 'https://theway126.com/testimonial.html',
        FAQ_URL: Platform.OS == 'web' ? "https://theway126.com/faq.html" : 'https://theway126.com/faqs.html',
        TnC: Platform.OS == 'web' ? "https://theway126.com/terms.html" : 'https://theway126.com/terms-of-service.html',
        PrivacyPolicy: Platform.OS == 'web' ? "https://theway126.com/privacy_policy.html" : 'https://theway126.com/privacy-policy.html',
        EULA: Platform.OS == 'web' ? 'https://theway126.com/EULA-WEB.html' : 'https://theway126.com/EULA.html'
    },
    successCode: 1,

    ScreensName: {
        home: "Home",
        journey: "My Way",
        theWay: "The Way 126",
        expansion: "Expansion",
        source: "Source",
        level: "level"
    },

    paymentType: {

        subscription: "subscription",
        payment: "payment",
    },

    AppInfo: {
        name: 'The Way 126'
    },

    subscriptionAmount: {
        monthly: '126.99'
    },

    subscriptionProductIos: {
        monthly: 'monthlyPlanForAppAccess'
    },

    subscriptionProductAndroid: {
        monthly: 'monthly_plan_for_app_access'
    }

}